































































































































import Vue, { PropType } from 'vue'
import { IFraudTransaction } from '@/dome/types/FraudTypes'
import CheckIconContents from '@/dome/components/fraudTransactions/columns/CheckIconContents.vue'
import FraudStatusColumn from '@/dome/components/fraudTransactions/columns/FraudStatusColumn.vue'
import InfoColumnHeader from '@/dome/components/fraudTransactions/columns/InfoColumnHeader.vue'
import ChangeStatusColumn from '@/dome/components/fraudTransactions/columns/ChangeStatusColumn.vue'
import FraudTooltip from '@/dome/components/FraudTooltip.vue'
import dayjs from 'dayjs'
import { locales } from '@/dome/constants/locales'

export default Vue.extend({
  name: 'TransactionTable',
  components: { FraudTooltip, ChangeStatusColumn, InfoColumnHeader, FraudStatusColumn, CheckIconContents },
  props: {
    transactions: {
      type: Array as PropType<Array<IFraudTransaction>>,
      required: true
    }
  },
  computed: {
    columnLocales(): Dictionary<string> {
      return locales.transactionColumns
    }
  },
  methods: {
    convertDateFormat(date: Date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    }
  }
})
