













import Vue from 'vue'
import { mapGetters } from 'vuex'
import { FraudTransactionGetters } from '@/dome/store/fraudTransactionState'
import { IMemoLine } from '@/dome/types/IMemoTimeLine'
import DomeMemoLine from '@/dome/container/DomeTransactionalInfo/DomeMemoLine.vue'
import { IFraudTimeLine } from '@/dome/types/FraudTypes'
import dayjs from 'dayjs'

export default Vue.extend({
  components: { DomeMemoLine },
  data(): {
    memoLineList: Array<IMemoLine>
  } {
    return {
      memoLineList: []
    }
  },
  computed: {
    ...mapGetters('fraudTransactionState', {
      fraudTransactionDetail: FraudTransactionGetters.FraudTransactionDetail
    })
  },
  created: async function () {
    await this.setMemoLineList()
  },
  methods: {
    async setMemoLineList(): Promise<void> {
      this.memoLineList = []
      this.memoLineList = this.convertTimeLinesToList(this.fraudTransactionDetail.fraud_timeline)
    },
    convertTimeLinesToList(timeLines: Array<IFraudTimeLine>): Array<IMemoLine> {
      return timeLines.map(timeLine => {
        return {
          id: timeLine.id,
          created_at: dayjs(timeLine.created_at).format('YYYY-MM-DD'),
          action: timeLine.action,
          content: timeLine.param.content,
          writer: timeLine.param.writer
        }
      })
    }
  }
})
