














import Vue from 'vue'
export default Vue.extend({
  props: {
    description: {
      type: String,
      required: true
    }
  }
})
