













import Vue from 'vue'
import FilterTextInput from '@/dome/components/filter/inputs/FilterTextInput.vue'

export default Vue.extend({
  name: 'FilterCodeContainer',
  components: { FilterTextInput },
  data () {
    return {
      code_filter: {
        tid: '',
        order_number: '',
        card_number: '',
        seller_id: ''
      },
      labels: {
        tid: this.$t('dome.tid'),
        order_number: this.$t('dome.transaction.number'),
        card_number: this.$t('dome.card.last.digit'),
        seller_id: this.$t('dome.seller.id')
      }
    }
  },
  methods: {
    onChange () {
      this.$emit('onChange', this.code_filter)
    }
  }
})
