

























import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { FraudTransactionActions, FraudTransactionGetters } from '@/dome/store/fraudTransactionState'
import SubDashboardWrapper from '@/dome/components/subDashboard/SubDashboardWrapper.vue'
import DomeAlertModal from '@/dome/container/DomeTransactionalInfo/DomeAlertModal.vue'
import dayjs from 'dayjs'
import { IFraudStaticsAlert } from '@/dome/types/FraudTypes'
import { FraudAlertLabel } from '@/dome/constants/FraudStatus'
import { locales } from '@/dome/constants/locales'

export default Vue.extend({
  name: 'SubDashboardContainer',
  components: { SubDashboardWrapper, DomeAlertModal },
  data() {
    return {
      isDomeAlertModalOpen: false,
      domeAlertModalType: '',
      domeAlertModalState: FraudAlertLabel.NONE,
      domeAlertModalStateToString: '',
      value1: '',
      value2: '',
      searchedPeriod: '',
      stateClassObject: {
        [FraudAlertLabel.NORMAL]: 'normal',
        [FraudAlertLabel.WARNING]: 'caution',
        [FraudAlertLabel.DANGER]: 'danger',
        [FraudAlertLabel.WATCHING]: 'visa'
      }
    }
  },
  computed: {
    ...mapGetters('fraudTransactionState', {
      statics: FraudTransactionGetters.FraudStatics,
      staticsAlert: FraudTransactionGetters.FraudStaticsAlert
    }),
    staticLabels(): Dictionary<string> {
      const fraudStaticsResponse = this.statics
      const billingAmount = Number(fraudStaticsResponse.total_amount)
      const blockAmount = Number(fraudStaticsResponse.block_amount)
      const blockCount = Number(fraudStaticsResponse.block_count)
      const totalCount = Number(fraudStaticsResponse.total_count)
      const billingLabel = this.convertAbbrAmount(billingAmount)
      const blockLabel = this.convertAbbrAmount(blockAmount)
      const blockCountLabel = this.convertAbbrAmount(blockCount)
      const totalCountLabel = this.convertAbbrAmount(totalCount)
      return {
        billing: `$ ${billingLabel}`,
        fraud_block_billing: `$ ${blockLabel}`,
        fraud_block_and_trading_volume: `${blockCountLabel} / ${totalCountLabel}`,
        fraud_block_trade_rate: Number(fraudStaticsResponse.total_count)
          ? `${((Number(fraudStaticsResponse.block_count) / Number(fraudStaticsResponse.total_count)) * 100).toFixed(
              2
            )}%`
          : '0%'
      }
    },
    /**
     * staticsAlert 값으로 계산한 fraud 및 dispute alert 버튼의 상태명을 나타낸다.
     *
     * - fraud 버튼의 상태는 fraudRatio, fraudAmount로 계산한 상태 중 더 안전한 상태
     * - dispute 버튼의 상태는 disputeCount, disputeRatio로 계산한 상태 중 더 안전한 상태
     *
     * 각각의 계산법은 각 모달 하단 '1달 기준 유지 조건' 섹션에 적시돼있음
     *
     * @type {{ fraud: FraudAlertLabel; dispute: FraudAlertLabel }} fraud 및 dispute alert 버튼 레이블 타입
     */
    statusButtonLabel(): { fraud: FraudAlertLabel; dispute: FraudAlertLabel } {
      const statics: IFraudStaticsAlert = this.staticsAlert
      const fraudRatio = ((statics.chargeback_fraud_amount / statics.pass_amount) * 100) / 0.65
      const fraudAmount = statics.chargeback_fraud_amount / 50000
      const disputeRatio = (((statics.dispute_won_count + statics.dispute_lost_count) / statics.pass_count) * 100) / 0.9
      const disputeCount = (statics.dispute_won_count + statics.dispute_lost_count) / 100
      const disputeCountAlert: number = this.decideAlertLabel(disputeCount)
      const disputeAmountAlert: number = this.decideAlertLabel(disputeRatio)
      const fraudCountAlert: number = this.decideAlertLabel(fraudRatio)
      const fraudAmountAlert: number = this.decideAlertLabel(fraudAmount)
      const disputeAlert = Math.min(disputeCountAlert, disputeAmountAlert)
      const fraudAlert = Math.min(fraudCountAlert, fraudAmountAlert)
      return {
        dispute: disputeAlert,
        fraud: fraudAlert
      }
    }
  },
  /**
   * created life cycle에 statics와 staticsAlert 정보를 가져온다.
   *
   * 기간은 현재월 기준
   */
  async created() {
    const fromDate: Date = dayjs().startOf('month').startOf('day').toDate()
    const toDate: Date = dayjs().endOf('day').toDate()
    this.searchedPeriod = `${dayjs(fromDate).format('YYYY.MM.DD')} ~ ${dayjs(toDate).format('YYYY.MM.DD')}`
    await this.getStaticsAlert()
    await this.getStatics()
  },
  methods: {
    ...mapActions('fraudTransactionState', {
      getStatics: FraudTransactionActions.FetchFraudStatics,
      getStaticsAlert: FraudTransactionActions.FetchFraudStaticsAlert
    }),
    /**
     * 퍼센트를 토대로 계산한 경고 상태 수준을 리턴한다.
     *
     * - 0.5 미만 : NORMAL
     * - 0.5 이상 0.7 미만 : WARNING
     * - 0.7 이상 1 미만 : DANGER
     * - 1 이상 : WATCHING
     *
     * @param {number} targetAlert 계산할 퍼센트
     * @return {FraudAlertLabel} 계산한 경고 상태
     */
    decideAlertLabel(targetAlert: number): FraudAlertLabel {
      return targetAlert < 0.5
        ? FraudAlertLabel.NORMAL
        : targetAlert < 0.7
        ? FraudAlertLabel.WARNING
        : targetAlert < 1
        ? FraudAlertLabel.DANGER
        : FraudAlertLabel.WATCHING
    },
    convertAbbrAmount(targetAmount: number): string {
      let digits = ['K', 'M', 'B']
      let digit = ''
      for (const digitIndex in digits) {
        if (targetAmount <= 999999999) break
        targetAmount = Math.floor(targetAmount / 1000)
        digit = digits[digitIndex]
      }
      return `${Math.round(targetAmount).toLocaleString()}${digit}`
    },
    makeFirstLetterCapital(target: string): string {
      return target.charAt(0).toUpperCase() + target.slice(1)
    },
    /**
     * '부정 거래 비율'과 '부정 거래'를 계산하여 각각 객체의 value1, value2에 할당해 리턴한다.
     *
     * - '부정 거래 비율'(value1) : 시스템탐지 결과(pass_amount) 대비 Chargeback-Fraud 비율 (백분율)
     * - '부정 거래'(value2) : Chargeback-Fraud 액수 (천 달러 단위)
     */
    calcFraudModalInfo(): { value1: string; value2: string } {
      return {
        value1: ((this.staticsAlert.chargeback_fraud_amount / this.staticsAlert.pass_amount) * 100).toFixed(2),
        value2: (this.staticsAlert.chargeback_fraud_amount / 1000).toFixed(2)
      }
    },
    /**
     * '분쟁 신첱 비율'과 '분쟁 신청'을 각각 계산하여 각각 객체의 value1, value2에 할당해 리턴한다.
     *
     * - '분쟁 신청 비율' (value1) : (분쟁 승소 (dispute_won_count) + 분쟁 패소 (dispute_lost_count)) / 시스템 탐지결과 (pass_count) (백분율)
     * - '분쟁 신청' (value2) : 분쟁 승소 (dispute_won_count) + 분쟁 패소 (dispute_lost_count) (건 단위)
     */
    calcDisputeModalInfo(): { value1: string; value2: string } {
      return {
        value1: (
          ((this.staticsAlert.dispute_won_count + this.staticsAlert.dispute_lost_count) /
            this.staticsAlert.pass_count) *
          100
        ).toFixed(2),
        value2: `${this.staticsAlert.dispute_won_count + this.staticsAlert.dispute_lost_count}`
      }
    },
    /**
     * Fraud Alert 버튼이 눌렸을 때, Fraud Alert 모달을 띄운다.
     *
     * - domeAlertModalType, domeAlertModalState, domeAlertModalStateToString에 fraud와 관련된 값을 할당
     * - calcFraudModalInfo로 value1, value2 값 계산
     * - isDomeAlertModalOpen에 true 할당
     */
    onFraudAlertClick(): void {
      this.domeAlertModalType = 'fraud'
      this.domeAlertModalState = this.statusButtonLabel.fraud
      this.domeAlertModalStateToString = locales.fraudAlert[this.statusButtonLabel.fraud]
      const calculatedModalInfo = this.calcFraudModalInfo()
      this.value1 = calculatedModalInfo.value1
      this.value2 = calculatedModalInfo.value2
      this.isDomeAlertModalOpen = true
    },
    /**
     * Dispute Alert 버튼이 눌렸을 때, Dispute Alert 모달을 띄운다.
     *
     * - domeAlertModalType, domeAlertModalState, domeAlertModalStateToString에 dispute와 관련된 값을 할당
     * - calcDisputeModalInfo로 value1, value2 값 계산
     * - isDomeAlertModalOpen에 true 할당
     */
    onDisputeAlertClick(): void {
      this.domeAlertModalType = 'dispute'
      this.domeAlertModalState = this.statusButtonLabel.dispute
      this.domeAlertModalStateToString = locales.fraudAlert[this.statusButtonLabel.dispute]
      const calculatedModalInfo = this.calcDisputeModalInfo()
      this.value1 = calculatedModalInfo.value1
      this.value2 = calculatedModalInfo.value2
      this.isDomeAlertModalOpen = true
    }
  }
})
