






































































import Vue from 'vue'
import TransactionFilterWrapper from '@/dome/components/filter/TransactionFilterWrapper.vue'
import FilterDivision from '@/dome/components/filter/inputs/FilterDivision.vue'
import FilterPeriodContainer from '@/dome/container/filter/FilterPeriodContainer.vue'
import dayjs from 'dayjs'
import FilterCurrencyContainer from '@/dome/container/filter/FilterCurrencyContainer.vue'
import FilterStatusContainer from '@/dome/container/filter/FilterStatusContainer.vue'
import FilterAmountContainer from '@/dome/container/filter/FilterAmountContainer.vue'
import FilterSystemContainer from '@/dome/container/filter/FilterSystemContainer.vue'
import FilterCodeContainer from '@/dome/container/filter/FilterCodeContainer.vue'
import { mapActions } from 'vuex'
import { FraudTransactionActions } from '@/dome/store/fraudTransactionState'
import { FraudStatus } from '@/dome/constants/FraudStatus'

export default Vue.extend({
  name: 'TransactionFilterContainer',
  components: {
    FilterCodeContainer,
    FilterSystemContainer,
    FilterAmountContainer,
    FilterStatusContainer,
    FilterCurrencyContainer,
    FilterPeriodContainer,
    FilterDivision,
    TransactionFilterWrapper
  },
  data () {
    return {
      isChecked: {
        period: false,
        currency: false,
        amount: false,
        system_status: false,
        transaction_status: false,
        code_filter: false
      },
      hasCheckedOnce: {
        period: false,
        currency: false,
        amount: false,
        system_status: false,
        transaction_status: false,
        code_filter: false
      },
      filter: {
        period: {
          from: dayjs().startOf('day').toDate(),
          to: dayjs().endOf('day').toDate()
        },
        currency: ['KRW'],
        amount: { from: 100000, to: 10000000, currency: 'KRW' },
        system_status: [FraudStatus.FRAUD_BLOCK],
        transaction_status: [FraudStatus.FRAUD_BLOCK],
        code_filter: {}
      }
    }
  },
  computed: {
    requestFilterParam (): Dictionary<any> {
      const filter: Dictionary<any> = Object.assign({}, this.filter)
      Object.keys(filter).forEach(key => {
        if (!this.isChecked[key as never]) delete filter[key]
      })
      return filter
    }
  },
  methods: {
    ...mapActions('fraudTransactionState', {
      fetchApplyFilter: FraudTransactionActions.ApplyFraudFilter
    }),
    isCheckedPeriod (isCheckedPeriod: boolean) {
      this.isChecked.period = isCheckedPeriod
    },
    onChangePeriod (period: { from: Date, to: Date }) {
      this.filter.period = period
    },
    isCheckedCurrency (isCheckedCurrency: boolean) {
      this.isChecked.currency = isCheckedCurrency
    },
    onChangeCurrency (currencies: Array<never>) {
      this.filter.currency = currencies
    },
    isCheckedAmount (isCheckedAmount: boolean) {
      this.isChecked.amount = isCheckedAmount
    },
    onChangeAmount (amount: { from: number; to: number; currency: string }) {
      this.filter.amount = amount
    },
    isCheckedSystem (isCheckedSystem: boolean) {
      this.isChecked.system_status = isCheckedSystem
    },
    onChangeSystem (statuses: any) {
      this.filter.system_status = statuses
    },
    isCheckedStatus (isCheckedStatus: boolean) {
      this.isChecked.transaction_status = isCheckedStatus
    },
    onChangeStatus (status: any) {
      this.filter.transaction_status = status
    },
    isCheckedCode (isCheckedCode: boolean) {
      this.isChecked.code_filter = isCheckedCode
    },
    onChangeCode (codeFilter: {
      tid: string,
      order_number: string,
      card_number: string,
      seller_id: string,
      corp_name: string
    }) {
      this.filter.code_filter = codeFilter
    },
    async resetFilter () {
      this.isChecked = {
        period: false,
        currency: false,
        amount: false,
        system_status: false,
        transaction_status: false,
        code_filter: false
      }
      this.hasCheckedOnce = {
        period: false,
        currency: false,
        amount: false,
        system_status: false,
        transaction_status: false,
        code_filter: false
      }
      this.filter = {
        period: {
          from: dayjs().startOf('day').toDate(),
          to: dayjs().endOf('day').toDate()
        },
        currency: ['KRW'],
        amount: { from: 100000, to: 10000000, currency: 'KRW' },
        system_status: [FraudStatus.FRAUD_BLOCK],
        transaction_status: [FraudStatus.FRAUD_BLOCK],
        code_filter: {}
      }
      await this.fetchApplyFilter(this.requestFilterParam)
      this.$emit('onResetFilter')
    },
    async applyFilter () {
      await this.fetchApplyFilter(this.requestFilterParam)
      this.$emit('onApplyFilter')
    }
  }
})
